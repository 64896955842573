/**
 * call
 * 呼叫
 */

import request from '@/utils/request'

// 查询商机
export const select = (data) => {
    return request({
        method: 'post',
        url: '/business/select',
        data
    })
}

// 新建商机
export const add = (data) => {
    return request({
        method: 'post',
        url: '/business/add',
        data
    })
}

// 删除商机
export const deleteNiche = (data) => {
    return request({
        method: 'post',
        url: '/business/delete',
        data
    })
}

// 编辑商机
export const update = (data) => {
    return request({
        method: 'post',
        url: '/business/update',
        data
    })
}