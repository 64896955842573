<template>
  <div id="clientNums">
    <el-card>
      <header>
        <h2 style="display: flex; align-items: center">
          <el-page-header
            @back="goBack"
            title="返回上页"
            content="客户列表"
          ></el-page-header>
          <div style="display: flex; align-items: center">
            <span v-if="roleName == '企业' && showTitleText"
              >(查看企业端已导入客户的全部数据)</span
            >
            <span v-if="roleName == '组长' && showTitleText"
              >(主要是查看组员以及自己已导入客户的数据)</span
            >
            <span v-if="roleName == '人员' && showTitleText"
              >(查看员工端已导入客户的全部数据)</span
            >
            <el-tooltip
              class="item"
              effect="dark"
              content="点击查看详情说明"
              placement="top"
            >
              <img
                v-if="!showTitleText"
                src="@/assets/images/what.png"
                alt=""
                style="width: 26px; height: 26px; cursor: pointer"
                @click="showTitleText = true"
              />
            </el-tooltip>
          </div>
        </h2>
        <!-- <el-card id="clientNumber">
          <div style="display: flex; justify-content: space-between">
            <div class="num-wrap" style="background-color: #3278FE; border-radius: 5px">
              <div class="number">{{ ybkh_status }} 位</div>
              <div class="title">一般客户</div>
            </div>
            <div class="num-wrap" style="background-color: #3278FE; border-radius: 5px">
              <div class="number">{{ yxkh_status }} 位</div>
              <div class="title">意向客户</div>
            </div>
            <div class="num-wrap" style="background-color: #3278FE; border-radius: 5px">
              <div class="number">{{ wyxkh_status }} 位</div>
              <div class="title">无意向客户</div>
            </div>
            <div class="num-wrap" style="background-color: #3278FE; border-radius: 5px">
              <div class="number">{{ cjkh_status }} 位</div>
              <div class="title">成交客户</div>
            </div>
            <div class="num-wrap" style="background-color: #3278FE; border-radius: 5px">
              <div class="number">{{ hmd_status }} 位</div>
              <div class="title">黑名单</div>
            </div>
          </div>
        </el-card> -->
        <div class="search">
          <div class="left">
            <div class="left-wrap">
              <span>号码</span>
              <el-input
                v-model="activePhone"
                placeholder="请输入完整号码"
                size="mini"
                clearable
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="handleSearch"
                ></el-button>
              </el-input>
            </div>

            <div style="margin-left: 10px">
              <el-button
                size="mini"
                v-show="isDivVisible"
                @click="setisDivVisible(false)"
                >取消高级搜索</el-button
              >
              <el-button
                type="success"
                v-show="!isDivVisible"
                plai
                size="mini"
                @click="isDivVisible = true"
                >高级搜索</el-button
              >
            </div>
            <div style="margin-left: 10px">
              <el-button
                v-show="!showBtnList"
                style=""
                type="success"
                @click="showBtnList = true"
                size="mini"
                >更多设置
              </el-button>
              <!-- icon="el-icon-d-arrow-left" -->
              <div v-show="showBtnList" class="transition-box">
                <el-button
                  v-show="showBtnList"
                  icon="el-icon-d-arrow-right"
                  size="mini"
                  @click="showBtnList = false"
                  >收起</el-button
                >
                <el-button
                  type="success"
                  icon="el-icon-search"
                  @click="handleSearch"
                  size="mini"
                  class="searchBtn"
                  >查找
                </el-button>
                <el-button
                  class="reset"
                  type="success"
                  icon="el-icon-refresh"
                  @click="reset()"
                  size="mini"
                  >重置
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-s-operation"
                  @click="openCustom"
                  size="mini"
                  v-if="roleName == '企业'"
                  class="searchBtn"
                  >自定义表头
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-s-operation"
                  @click="customColumnsShow = true"
                  size="mini"
                  class="searchBtn"
                  >自定义列
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-search"
                  @click="allHandleSearch"
                  size="mini"
                  class="searchBtn"
                  >全局查找
                </el-button>
                <el-button
                  type="success"
                  @click="distributeNums()"
                  size="mini"
                  class="distributeNums"
                  v-if="roleName != '人员'"
                  >分配号码
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-folder-opened"
                  @click="allNums()"
                  size="mini"
                  class="allNums"
                  >批量导入号码
                </el-button>
                <el-button
                  type="success"
                  icon="el-icon-folder-opened"
                  @click="addNums()"
                  size="mini"
                  class="addNums"
                  >添加号码
                </el-button>
                <el-tooltip
                  content="释放/批量释放：将员工已拨打的数据进行释放到【部门公海】、【公司公海】进行二次分配且只有公司公海能回收到回收站进行删除"
                  effect="light"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    @click="moreDelete()"
                    class="delete"
                    size="mini"
                    icon="el-icon-delete"
                    >批量释放
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <!-- <div class="left-wrap" v-if="realname == 'admin'">
              <span>企业</span>
              <el-input v-model="company" placeholder="请输入公司名称" filterable size="mini"></el-input>
            </div>
            <div class="left-wrap">
              <span>行业</span>
              <el-input v-model="industry" placeholder="请输入行业" size="mini"></el-input>
            </div>
            <div class="left-wrap">
              <span>客户来源</span>
              <el-input v-model="customerSource" placeholder="请输入客户来源" size="mini"></el-input>
            </div>
            <div class="left-wrap" v-if="roleName == '组长'">
              <span>员工</span>
              <el-select v-model="id1" placeholder="请选择员工" filterable size="mini">
                <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
              <span>部门</span>
              <el-select v-model="departId" placeholder="请选择部门" filterable size="mini">
                <el-option v-for="item in underDepart" :key="item.id" :label="item.departName" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap" v-if="roleName == '管理员' || roleName == '企业'">
              <span>员工</span>
              <el-select v-model="userId" placeholder="请选择员工" filterable size="mini">
                <el-option v-for="item in underUser" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="left-wrap">
              <span>导入时间</span>
              <el-date-picker v-model="createTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="left-wrap">
              <span>拨打时间</span>
              <el-date-picker v-model="changeTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="left-wrap">
              <span>更新时间</span>
              <el-date-picker v-model="getTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                end-placeholder="结束日期" align="center" size="mini" :picker-options="pickerOptions">
              </el-date-picker>
            </div> -->
          </div>
          <transition name="el-zoom-in-top">
            <div v-show="isDivVisible" class="transition-box left vipSearch">
              <div class="left-wrap" v-if="roleName == '组长'">
                <span>员工</span>
                <el-select
                  v-model="id1"
                  placeholder="请选择员工"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="item in underUser"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div
                class="left-wrap"
                v-if="roleName == '管理员' || roleName == '企业'"
              >
                <span>员工</span>
                <el-select
                  v-model="userId"
                  placeholder="请选择员工"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="item in underUser"
                    :key="item.id"
                    :label="item.realname"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="left-wrap">
                <span>{{ getNewLabel("企业", "company") }}</span>
                <el-input
                  v-model="company"
                  :placeholder="`请输入${getNewLabel('企业', 'company')}`"
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap" v-if="realname == 'admin'">
                <span>{{ getNewLabel("企业", "company") }}</span>
                <el-input
                  v-model="company"
                  :placeholder="`请输入${getNewLabel('企业', 'company')}`"
                  filterable
                  size="mini"
                ></el-input>
              </div>
              <div class="left-wrap">
                <span>{{ getNewLabel("行业", "industry") }}</span>
                <el-input
                  v-model="industry"
                  :placeholder="`请输入${getNewLabel('行业', 'industry')}`"
                  size="mini"
                ></el-input>
              </div>
              <!-- <div class="left-wrap">
                <span>{{ getNewLabel('姓名', 'customerPhone') }}</span>
                <el-input v-model="customerPhone" :placeholder="`请输入${getNewLabel('姓名', 'customerPhone')}`"
                  size="mini"></el-input>
              </div> -->
              <div class="left-wrap">
                <span>客户来源</span>
                <el-select
                  v-if="ifParentId == 7"
                  v-model="customerSource"
                  placeholder="请选择客户来源"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="(item, index) in customerSourceOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="customerSource"
                  placeholder="请输入客户来源"
                  size="mini"
                ></el-input>
              </div>

              <div class="left-wrap">
                <span>备注</span>
                <el-input
                  v-model="notes"
                  placeholder="请输入备注"
                  size="mini"
                ></el-input>
              </div>

              <div
                class="left-wrap"
                v-if="roleName == '管理员' || roleName == '企业'"
              >
                <span>部门</span>
                <el-select
                  v-model="departId"
                  placeholder="请选择部门"
                  filterable
                  size="mini"
                >
                  <el-option
                    v-for="item in underDepart"
                    :key="item.id"
                    :label="item.departName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="left-wrap">
                <span>导入时间</span>
                <el-date-picker
                  v-model="createTime"
                  type="datetimerange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="center"
                  size="mini"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </div>
          </transition>

          <div class="right"></div>
        </div>
      </header>
      <main>
        <el-tabs v-model="activeData" @tab-click="clickData">
          <el-tab-pane label="成交客户" name="cjkh">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                fixed
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column> -->
              <!-- <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column> -->
              <!-- <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->

              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="意向客户" name="yxkh">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
              <el-table-column
                prop="activePhone"
                label="手机号码"
                width="120"
                align="center"
                fixed
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>

              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="一般客户" name="ybkh">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="无意向客户" name="wyxkh">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                fixed
                width="120"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                fixed
                width="120"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="黑名单" name="hmd">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="无状态" name="wzt">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                fixed
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
                width="120"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                width="120"
                fixed
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="未接通" name="wjt">
            <el-table
              :data="openedArrObj[cutIndex]"
              :class="filterEnterprise()"
              class="table"
              ref="multipleTable"
              header-cell-class-name="table-header"
              @selection-change="handleSelectionChange"
              @row-dblclick="dblclick"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
              ></el-table-column>
              <!-- <el-table-column prop="userRealname" label="归属人" align="center"></el-table-column> -->
              <el-table-column
                prop="activePhone"
                label="手机号码"
                align="center"
                fixed
                width="120"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="手机号码"
                align="center"
                fixed
                width="120"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-table-column>
              <el-table-column
                :prop="v.prop"
                :label="v.label"
                align="center"
                v-for="v in newColumnsData"
                :key="v.key"
                width="160"
              ></el-table-column>
              <!-- <el-table-column prop="company" label="企业名称" align="center"></el-table-column>
              <el-table-column prop="customerPhone" label="姓名" align="center"></el-table-column>

              <el-table-column prop="industry" label="行业" align="center"></el-table-column>
              <el-table-column prop="address" label="地址" align="center"></el-table-column>
              <el-table-column prop="status" label="状态" align="center">
                <template #default="scope">
                  <span v-if="scope.row.status == 0">一般客户</span>
                  <span v-if="scope.row.status == 1">意向客户</span>
                  <span v-if="scope.row.status == 2">无意向客户</span>
                  <span v-if="scope.row.status == 3">成交客户</span>
                  <span v-if="scope.row.status == 4">黑名单</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="导入时间" align="center"></el-table-column>
              <el-table-column prop="changeTime" label="拨打时间" align="center"></el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" class="hide"></el-table-column>
              <el-table-column prop="choosePeople" label="决策人" align="center"></el-table-column>
              <el-table-column prop="customerScale" label="公司规模" align="center"></el-table-column>
              <el-table-column prop="customerSource" label="客户来源" align="center"></el-table-column> -->
              <el-table-column
                label="操作"
                width="280"
                align="center"
                fixed="right"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    @click.stop="call(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >拨打
                  </el-button>
                  <el-button
                    type="success"
                    @click="addBtn(scope)"
                    v-if="roleName != '企业'"
                    size="mini"
                    >短信提醒跟进
                  </el-button>
                  <!-- 释放号码 -->
                  <el-button
                    type="danger"
                    @click="removeButton(scope.row, scope.$index)"
                    size="mini"
                    >释放
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </main>
    </el-card>
    <!--分页  -->
    <div class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next,sizes,jumper"
        :current-page="pageIndex"
        :page-size="pageSize"
        :page-sizes="[10, 20, 50, 100, 200, 300]"
        :total="pageTotal"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>

    <!-- 短信提醒跟进弹出框 -->
    <el-dialog title="短信提醒跟进" :visible.sync="addVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="提醒时间" prop="sendTime">
          <el-date-picker
            v-model="form.sendTime"
            type="datetime"
            placeholder="选择提醒时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="form.keqing_remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitaddValueS()">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 释放弹出框 -->
    <el-dialog title="释放" :visible.sync="removeVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="removeValue" @change="oneSlt">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="removeVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="confirm()" v-if="removeValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails"
            v-else-if="removeValue == 0"
            :disabled="disabled1"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 部门选择弹出框 -->
    <el-dialog title="释放" :visible.sync="depVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select
            v-model="removeParams.departId"
            placeholder="请选择释放位置"
            filterable
          >
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(removeVisible = true), (depVisible = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量释放弹出框 -->
    <el-dialog title="批量释放" :visible.sync="batchRemoveVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="batchRemoveValue" @change="oneSltBatch">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="batchRemoveVisible = false"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirm1()"
            v-if="batchRemoveValue == 1"
            >确 定</el-button
          >
          <el-button
            type="primary"
            @click="depDetails1"
            v-else-if="batchRemoveValue == 0"
            :disabled="disabled2"
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 批量部门选择弹出框 -->
    <el-dialog title="批量释放" :visible.sync="depVisibleBatch" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="释放位置">
          <el-select v-model="departId" placeholder="请选择释放位置" filterable>
            <el-option
              v-for="item in freeDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="(batchRemoveVisible = true), (depVisibleBatch = false)"
            >上一步</el-button
          >
          <el-button type="primary" @click="step1()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog :visible.sync="wrapVisible" class="dblclick">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="编辑" name="update">
          <el-form ref="form" :model="form" label-width="100px" class="update">
            <el-form-item label="客户号码">
              <el-input
                v-model="form.phone"
                placeholder="请输入客户号码"
                v-if="(hideNum || id == 5051) && roleName != '企业'"
              ></el-input>
              <el-input
                v-model="form.activePhone"
                placeholder="请输入客户号码"
                v-if="(!hideNum && id != 5051) || roleName == '企业'"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="客户姓名">
              <el-input v-model="form.customerPhone" placeholder="请输入客户姓名"></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="决策人">
              <el-input v-model="form.choosePeople" placeholder="请输入决策人"></el-input>
            </el-form-item>
            <el-form-item label="公司规模">
              <el-input v-model="form.customerScale" placeholder="请输入公司规模"></el-input>
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="客户来源">
              <el-input v-model="form.customerSource" placeholder="请输入客户来源"></el-input>
            </el-form-item>
            <el-form-item label="行业">
              <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="form.address" placeholder="请输入地址"></el-input>
            </el-form-item> -->

            <el-form-item
              :label="v.label"
              v-for="v in editColumnsData"
              :key="v.key"
            >
              <div v-if="ifParentId == 7 && v.label == '客户来源'">
                <el-select
                  v-model="form.customerSource"
                  placeholder="请选择客户来源"
                  filterable
                >
                  <el-option
                    v-for="(item, index) in customerSourceOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div v-else>
                <el-input
                  v-model="form[v.prop]"
                  :placeholder="`请输入${v.label}`"
                ></el-input>
              </div>
            </el-form-item>

            <!-- <el-form-item
              :label="v.label"
              v-for="v in editColumnsData"
              :key="v.key"
            >
              <el-input
                v-model="form[v.prop]"
                :placeholder="`请输入${v.label}`"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="跟进记录">
              <el-input
                type="textarea"
                v-model="form.historyRecord"
                placeholder="请输入跟进记录(备注)"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-radio-group v-model="update_status" style="line-height: 30px">
                <el-radio :label="0">一般客户</el-radio>
                <el-radio :label="1">意向客户</el-radio>
                <el-radio :label="2">无意向客户</el-radio>
                <el-radio :label="3">成交客户</el-radio>
                <el-radio :label="4">黑名单</el-radio>
                <el-radio :label="5">无状态</el-radio>
                <el-radio :label="6">未接通</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="updateSubmit" style="width: 100%"
            >确 定
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="通话记录" name="callRecord">
          <el-row v-show="historys">
            <el-col :span="24">
              <el-table :data="soundTableDate" style="width: 100%">
                <el-table-column
                  prop="activePhone"
                  label="主叫"
                  align="center"
                  v-if="
                    (!hideNum && id != 5051) ||
                    realname == 'admin' ||
                    roleName == '企业'
                  "
                ></el-table-column
                ><el-table-column
                  prop="phone"
                  label="主叫"
                  align="center"
                  v-if="
                    id != 5051 &&
                    roleName != '企业' &&
                    hideNum &&
                    realname != 'admin'
                  "
                ></el-table-column>
                <el-table-column
                  prop="passivePhone"
                  label="被叫"
                  align="center"
                  v-if="
                    (!hideNum && id != 5051) ||
                    realname == 'admin' ||
                    roleName == '企业'
                  "
                ></el-table-column>
                <el-table-column
                  prop="phone1"
                  label="被叫"
                  align="center"
                  v-if="
                    id != 5051 &&
                    roleName != '企业' &&
                    hideNum &&
                    realname != 'admin'
                  "
                ></el-table-column>
                <el-table-column
                  prop="seconds"
                  label="秒数(s)"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="callStarttime"
                  label="开始时间"
                  sortable
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="callEndtime"
                  sortable
                  label="结束时间"
                  align="center"
                ></el-table-column>
                <el-table-column align="center">
                  <template #default="scope">
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      @click="startaudio(scope)"
                      v-if="scope.row.recordUrl != null"
                      size="mini"
                      >查看录音</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="跟进记录" name="trailLog">
          <el-row v-show="historys">
            <el-col :span="24">
              <el-table :data="upRecords" style="width: 100%">
                <el-table-column prop="data" label="跟进日期" width="">
                </el-table-column>
                <el-table-column prop="content" label="跟进内容" width="">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane label="跟进计划" name="followPlan">
          <el-table :data="dataList" style="width: 100%">
            <el-table-column prop="title" label="标题" width="">
            </el-table-column>
            <el-table-column prop="companyName" label="公司" width="">
            </el-table-column>
            <el-table-column prop="clentPhone" label="手机号" width="">
            </el-table-column>
            <el-table-column prop="followTime" label="跟进时间" width="">
            </el-table-column>
            <el-table-column prop="remarke" label="跟进内容" width="">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          label="新增商机"
          name="opportunity"
          v-if="ifParentId == 7 || id == 7"
        >
          <el-form ref="form" :model="form" label-width="100px" class="update">
            <el-form-item label="客户号码">
              <el-input
                v-model="form.phone"
                placeholder="请输入客户号码"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="客户姓名">
              <el-input
                disabled
                v-model="form.customerPhone"
                placeholder="请输入客户姓名"
              ></el-input>
            </el-form-item>

            <el-form-item label="商机阶段">
              <el-select
                v-model="form.shangjiStage"
                placeholder="请选择阶段"
                clearable
              >
                <el-option
                  v-for="item in JDoptions"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商机金额">
              <el-input
                v-model="form.money"
                clearable
                type="number"
                placeholder="请输入商机金额"
              ></el-input>
            </el-form-item>
            <el-form-item label="商机名称">
              <el-input
                v-model="form.name"
                placeholder="请设置商机名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="预计成交日期">
              <el-date-picker
                v-model="form.predictMakeTime"
                type="datetime"
                clearable
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                type="textarea"
                v-model="form.remarke"
                show-word-limit
                placeholder="商机相关备注"
                autosize
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="onSubmit" style="width: 100%"
            >确 定
          </el-button>
        </el-tab-pane>
        <!-- <el-tab-pane label="新增合同" name="contract" v-if="ifParentId != 7">
          <el-form ref="contractForm" :model="contractForm" label-width="100px">
            <div class="contract">
              <el-form-item label="合同名称">
                <el-input v-model="contractForm.contractName" placeholder="请输入合同名称"></el-input>
              </el-form-item>
              <el-form-item label="客户名称">
                <el-input v-model="contractForm.clientName" disabled></el-input>
                
              </el-form-item>
              <el-form-item label="合同金额">
                <el-input-number v-model="contractForm.contractMoney" :precision="1" :step="1"
                  :max="99999999"></el-input-number>
              </el-form-item>
              <el-form-item label="签约时间">
                <el-date-picker v-model="contractForm.orderDate" type="datetime" placeholder="选择签约时间">
                </el-date-picker>
              </el-form-item>
              
              <el-form-item label="公司名称">
                <el-input v-model="contractForm.companyName" disabled></el-input>
              </el-form-item>
              <el-form-item label="公司签约人">
                <el-input v-model="contractForm.companyUser" disabled></el-input>
            
              </el-form-item>
            </div>
            <el-form-item label="备注">
              <el-input type="textarea" v-model="contractForm.remark" placeholder="备注"></el-input>
            </el-form-item>
          
            <el-button type="primary" @click="addCP" style="margin-bottom: 20px">添加产品</el-button>
      
            <el-table :data="cpData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
              style="margin-bottom: 20px" @selection-change="handleSelectionChange">
              <el-table-column prop="productName" label="产品名字" align="center"></el-table-column>
              <el-table-column prop="procuctPrice" label="产品价格" align="center"></el-table-column>
             
              <el-table-column prop="unit" label="单位" align="center"></el-table-column>
              <el-table-column prop="sellingPrice" label="售价" align="center">
                <template #default="scope">
                  <el-input type="number" v-model="scope.row.sellingPrice"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="discountPrice" label="折扣" align="center">
                <template #default="scope">
                  <el-select v-model="scope.row.discountPrice" placeholder="请选择折扣" filterable>
                    <el-option v-for="item in discountPriceList" :key="item.id" :label="item.title" :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量" align="center">
                <template #default="scope">
                  <el-input type="number" v-model="scope.row.num"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="sumMoney" label="合计" align="center" :formatter="formatter">
              </el-table-column>
              <el-table-column label="操作" width="100" align="center" fixed="right">
                <template #default="scope">
                  <el-button type="primary" @click="del(scope.row)" size="mini">移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
          <el-button type="primary" @click="contractSubmit" style="width: 100%">确 定
          </el-button>
        </el-tab-pane> -->
        <el-tab-pane
          label="新增合同"
          name="contract"
          v-if="ifParentId == 7 || id == 7"
        >
          <el-form ref="sjyForm" :model="sjyForm" label-width="100px">
            <div class="contract">
              <el-form-item label="公司名称">
                <el-input
                  v-model="sjyForm.companyName"
                  placeholder="请输入公司名称"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="行业类型">
                <el-select
                  v-model="sjyForm.type"
                  placeholder="请选择行业类型"
                  filterable
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="客户来源">
                <h3>{{ sjyForm.customerSource }}</h3>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input
                  v-model="sjyForm.phone"
                  placeholder="请输入联系人"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话">
                <el-input
                  v-model="sjyForm.telephone"
                  placeholder="请输入电话"
                ></el-input>
              </el-form-item>
              <el-form-item label="产品类型">
                <el-select
                  v-model="sjyForm.businessHandling"
                  placeholder="请选择产品类型"
                  filterable
                >
                  <el-option
                    v-for="item in productTypeList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="业务类型">
                <el-select
                  v-model="sjyForm.businessType"
                  placeholder="请选择业务类型"
                  filterable
                >
                  <el-option
                    v-for="item in businessTypeList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="线路类型">
                <el-select
                  v-model="sjyForm.routeType"
                  placeholder="请选择线路类型"
                  filterable
                >
                  <el-option
                    v-for="item in circuitTypeList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="号码费">
                <el-input
                  v-model="sjyForm.numberFee"
                  placeholder="请输入号码费"
                ></el-input>
              </el-form-item>
              <el-form-item label="号码数量">
                <el-input
                  v-model="sjyForm.numberNum"
                  placeholder="请输入号码数量"
                ></el-input>
              </el-form-item>
              <el-form-item label="套餐(月)">
                <el-input
                  v-model="sjyForm.combo"
                  placeholder="请输入套餐(月)"
                ></el-input>
              </el-form-item>
              <el-form-item label="坐席数量">
                <el-input
                  v-model="sjyForm.seatsNumber"
                  placeholder="请输入坐席数量"
                ></el-input>
              </el-form-item>
              <el-form-item label="套餐内费率">
                <el-input
                  v-model="sjyForm.rateWithin"
                  placeholder="请输入套餐内费率"
                ></el-input>
              </el-form-item>
              <el-form-item label="套餐外费率">
                <el-input
                  v-model="sjyForm.rateExtra"
                  placeholder="请输入套餐外费率"
                ></el-input>
              </el-form-item>
              <el-form-item label="合作周期(月)">
                <el-input
                  v-model="sjyForm.cooperationTime"
                  placeholder="请输入合作周期(月)"
                ></el-input>
              </el-form-item>
              <el-form-item label="套餐外充值">
                <el-input
                  v-model="sjyForm.comboRecharge"
                  placeholder="请输入套餐外充值"
                ></el-input>
              </el-form-item>
              <el-form-item label="功能费">
                <el-input
                  v-model="sjyForm.featureFee"
                  placeholder="请输入功能费"
                ></el-input>
              </el-form-item>
              <el-form-item label="费用合计">
                <el-input
                  v-model="sjyForm.feeTotal"
                  placeholder="请输入费用合计"
                ></el-input>
              </el-form-item>
              <el-form-item label="赠送">
                <el-input
                  v-model="sjyForm.presented"
                  placeholder="请输入赠送"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否分期">
                <el-radio-group v-model="sjyForm.installment">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="月租模式">
                <el-radio-group v-model="sjyForm.whole">
                  <el-radio :label="1">30天模式</el-radio>
                  <el-radio :label="2">自然月模式</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <el-form-item label="备注">
              <el-input
                type="textarea"
                v-model="sjyForm.remarke"
                placeholder="备注"
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="sjySubmit" style="width: 100%"
            >确 定
          </el-button>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称" v-if="realname == 'admin'">
          <el-input
            v-model="form.company"
            placeholder="输入公司名称分配至公司公海"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input
            v-model="form.departName"
            placeholder="输入部门名称分配至部门海"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-select
            v-if="ifParentId == 7"
            v-model="form.customerSource"
            placeholder="请选择客户来源"
            filterable
          >
            <el-option
              v-for="(item, index) in customerSourceOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addOneVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog
      title="批量导入号码"
      :visible.sync="numShow"
      width="70%"
      class="num-upload"
      @close="clearNumber"
    >
      <div class="left">
        <div style="display: flex; align-items: center; margin-bottom: 10px">
          <div style="margin-right: 10px">号码检测</div>
          <el-switch
            style="display: block"
            v-model="numberTest"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="开启"
            inactive-text="关闭"
          >
          </el-switch>
        </div>
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          style="width: 100%"
          >点击下载模板
        </el-button>
        <el-upload
          ref="uplaod"
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-upload="beforeAvatarUpload"
          :before-remove="beforeRemove"
          :auto-upload="false"
          :data="carry"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          accept=".xls,.xlsx"
          :on-change="changeUpload"
          :drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="right">
        <div
          style="line-height: 36px; color: #606369"
          v-if="roleName == '企业'"
        >
          <p>
            1、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面只填写了手机号码，那么最终都会导入到【公司公海】
          </p>
          <p>
            2、不论在管理员账号的（客户列表、个人私海、部门公海、公司公海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、部门名字，最终都会到对应的【部门公海】
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能；如导入数据，部门名称填写错误，会默认导入公司公海
          </p>
        </div>
        <div
          style="line-height: 36px; color: #606369"
          v-if="roleName == '组长'"
        >
          <p>
            1、不论从组长账户的（客户列表、个人私海、部门公海）导入客户名单，在导入表格里面只填写了手机号，那么最终都会导入到组长的【个人私海】
          </p>
          <p>
            2、不论从组长账户的（客户列表、个人私海、部门公海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到组长的【部门公海】
          </p>
          <p>
            3、开启号码检测后将自动过滤掉无效的号码,未开通(即使开启)没有过滤号码的效果,需要开通则联系客服或管理员
          </p>
          <p style="color: red">
            注意：如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能。在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到组长的【部门公海】，在进行号码分配
          </p>
        </div>
        <div
          style="line-height: 36px; color: #606369"
          v-if="roleName == '人员'"
        >
          <p>
            1、不论从员工账户的（客户列表、个人私海）导入客户名单，在导入表格里面只填写了手机号，那么最终都会导入到坐席的【个人私海】
          </p>
          <p>
            2、不论从员工账户的（客户列表、个人私海）导入客户名单，在导入表格里面填写了手机号、以及坐席账户、本部门名字，最终都会导入到员工的【部门公海】
          </p>
          <p>
            3、开启号码检测后将自动过滤掉无效的号码,未开通(即使开启)没有过滤号码的效果,需要开通则联系客服或管理员
          </p>
          <p style="color: red">注意：</p>
          <p style="color: red; text-indent: 26px">
            1.如需要把客户名单导入部门，则必须由管理员创建部门后，才能够成功导入部门，反之则不能。员工端登录自己的账号，【批量导入号码】--下载模版，模板里手机号必填，其他按照需求填写保存，将文件拖到导入号码提示框里面导入
          </p>
          <p style="color: red; text-indent: 26px">
            2.导入号码时，只会识别表格第一行的数据和条件来判断将表格所有数据传入的位置。(文件上传最大3M,最多1万条数据,否则上传失败)
          </p>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="numShow = false">取 消</el-button> -->
          <el-button
            @click="numsSubmit(0)"
            :disabled="disabled"
            type="primary"
            style="width: 100%"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 全局查询客户弹出框 -->
    <el-dialog
      title="全局查询客户"
      :visible.sync="allNumShow"
      width="70%"
      class="num-upload"
      @close="clearNumber"
    >
      <div class="box11" style="width: 100%">
        <div class="allPhoneQuery">
          <div style="display: flex; flex: 4">
            <span style="width: 80px; line-height: 40px">查询号码</span>
            <el-input
              v-model="allPhoneActivePhone"
              placeholder="请输入完整号码"
              size="medium"
            ></el-input>
          </div>
          <el-button
            @click="allPhoneHandleSearch"
            type="primary"
            style="flex: 1"
            >查 询</el-button
          >
        </div>
        <div style="display: flex">
          <el-table
            :data="allPhoneTableData"
            border
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <el-table-column
              prop="activePhone"
              label="手机号码"
              align="center"
              fixed
              width="120"
              v-if="(!hideNum && id != 5051) || roleName == '企业'"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="手机号码"
              align="center"
              fixed
              width="120"
              v-if="(hideNum || id == 5051) && roleName != '企业'"
            ></el-table-column>

            <el-table-column
              :prop="v.prop"
              :label="v.label"
              align="center"
              v-for="v in newColumnsData"
              :key="v.key"
              width="100"
            ></el-table-column>

            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.status == 0">一般客户</span>
                <span v-if="scope.row.status == 1">意向客户</span>
                <span v-if="scope.row.status == 2">无意向客户</span>
                <span v-if="scope.row.status == 3">成交客户</span>
                <span v-if="scope.row.status == 4">黑名单</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="号码位置"
              align="center"
              fixed="right"
              width="100"
            >
              <template #default="scope">
                <span v-if="scope.row.userId">个人私海</span>
                <span v-if="!scope.row.userId && scope.row.departId"
                  >部门公海</span
                >
                <span
                  v-if="
                    !scope.row.userId &&
                    !scope.row.departId &&
                    scope.row.recycleBin
                  "
                  >公司公海</span
                >
                <span
                  v-if="
                    !scope.row.userId &&
                    !scope.row.departId &&
                    !scope.row.recycleBin
                  "
                  >回收站</span
                >
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="操作"
              width="280"
              align="center"
              
            >
              <template #default="scope">
                <el-button
                  type="success"
                  @click.stop="call(scope)"
                  v-if="roleName != '企业'"
                  size="mini"
                  >拨打
                </el-button>
                <el-button
                  type="success"
                  @click="addBtn(scope)"
                  v-if="roleName != '企业'"
                  size="mini"
                  >短信提醒跟进
                </el-button>
                <el-button
                  type="danger"
                  @click="removeButton(scope.row, scope.$index)"
                  size="mini"
                  >释放
                </el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </el-dialog>

    <!-- 自定义列表头 -->
    <el-dialog
      title="自定义隐藏列"
      :visible.sync="customColumnsShow"
      class="customColumns-dialog"
      width="50%"
      @close=""
    >
      <div style="">
        <el-transfer
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入列名称"
          v-model="customColumnsValue"
          :titles="['显示列', '隐藏列']"
          :button-texts="['显示', '隐藏']"
          :data="customColumnsData"
          @change="customColumnsChange"
        >
        </el-transfer>
        <!-- <span>{{ newColumnsData }}</span> -->
      </div>
    </el-dialog>

    <!-- 自定义表头标签 -->
    <el-dialog
      title="自定义表头标签"
      :visible.sync="customColumnsHead"
      class="customColumns-dialog"
      width="50%"
      @close=""
    >
      <div @click="logAd" style="margin-bottom: 30px">
        (点击标签修改文本,重要表头不可修改)
      </div>
      <!-- 可拖动标签改变排序或 -->
      <draggable v-model="customArr" axis="x" @change="draggableChange">
        <div
          class="head-box"
          v-for="(item, index) in customArr"
          v-if="getCustom(item)"
          @click="setCustomColumns(item)"
          :key="index"
          :style="{ left: `${index * 100}px` }"
          v-draggable="{ index: index, list: customArr }"
        >
          <span style="font-weight: bold">{{ item.label }} </span
          ><i class="el-icon-right"></i>
          <span :style="{ color: item.value ? 'red' : '' }">{{
            item.value || "未设置"
          }}</span>
        </div>
      </draggable>
      <!-- <template #footer>
        <el-button @click="customColumnsHead = false" style="margin-right: 10px">退 出
        </el-button>
        <span class="dialog-footer">
          <el-button type="primary" @click="setCustomHeadData">保存</el-button>
        </span>
      </template> -->
    </el-dialog>

    <!-- 录音弹出框 -->
    <el-dialog title="录音" :visible.sync="Showaudio" width="30%">
      <div style="margin-bottom: 20px">
        如无法播放录音，可清除浏览器缓存
        按键(ctrl+shift+delet)，重新启动浏览器，重新输入网址http://sys.cddmt.cn，重新登录即可
      </div>
      <div style="display: flex">
        <audio :src="currentAudio" controls="controls"></audio>
        <a
          :href="recordUrl"
          target="_blank"
          rel="noopener noreferrer"
          style="
            line-height: 54px;
            background-color: #3278fe;
            padding: 0 30px;
            box-sizing: border-box;
            border-radius: 30px;
            margin-left: 10px;
            color: #fff;
          "
          >无法播放点这里</a
        >
      </div>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog title="分配号码" :visible.sync="distributeShow" width="30%">
      <el-switch
        v-model="departUser"
        active-text="选择个人"
        inactive-text="选择部门"
        v-if="roleName != '组长'"
      >
      </el-switch>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="部门" v-if="roleName != '组长'">
          <el-select
            v-model="departId"
            placeholder="请选择部门，分配至部门海"
            :disabled="departUser"
            filterable
            v-if="roleName != '组长'"
          >
            <el-option
              v-for="item in underDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select
            v-model="userId"
            filterable
            placeholder="请选择个人"
            :disabled="!departUser"
          >
            <el-option
              v-for="item in underUser"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="distributeShow = false" style="margin-right: 10px"
          >退 出
        </el-button>
        <span class="dialog-footer">
          <el-button type="primary" @click="distributeSubmit"
            >确认提交</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      title="回拨已拨打"
      :visible.sync="callVisible"
      width="30%"
      center
    >
      <span
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: red;
        "
        >请在20秒内用手机拨打:{{ xphone }}</span
      >
    </el-dialog>

    <!-- sip弹出框 -->
    <el-dialog
      title="SIP呼叫"
      :visible.sync="sipCallVisible"
      v-if="sipCallVisible"
      width="30%"
      :before-close="handleClose"
      @close="hang_up"
    >
      <sipCall
        :callPhoneNum="sipPhone"
        :callPhoneData="callData"
        @showValue="showMethod"
      ></sipCall>
    </el-dialog>

    <!-- 添加产品弹出框 -->
    <el-dialog title="添加产品" :visible.sync="cpVisible" width="70%">
      <productData
        @confirm="handleConfirm"
        v-if="cpVisible"
        @productTableData="getTableData"
      />
    </el-dialog>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import XlsxPopulate from "xlsx-populate";

import { add } from "@/api/niche";
// 号码检测api
import { find, list } from "@/api/NnumberDetection";

// 旧版批量导入号码
import { smsImportPhone } from "@/api/sms";

import { confirmUserOnline, selectDzwlUserApi } from "@/api/dzwl";

import productData from "@/views/product/productData.vue";

import sipCall from "@/views/sip/sipCall.vue";
import clientsCharts from "@/views/charts/clientsCharts.vue";
import { callLogList } from "@/api/callLog";

import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { userSmsFindOne } from "@/api/user-sms";
import { reminderAdd } from "@/api/reminder";
import {
  phoneFindOneHistoryPhone,
  phoneFindOnePlanPhone,
  phoneFindUser,
  phoneList,
  findOne_all,
  phoneDistribute,
  phoneDelete,
  phoneUpdate,
  phoneImportPhone,
  phoneImportPhoneOld,
  phoneAdd,
} from "@/api/phone";
import { sysDepartList } from "@/api/sys_depart";
import { callAppBind } from "@/api/call";

import { contractAdd, addCollection } from "@/api/contract";
import { shangjiContractShangjiAdd } from "@/api/shangjiContract";
// import { userList1 } from "@/api/user";
import { customFindOne, customAddUpdate, customDel } from "@/api/phoneRecycle";

import draggable from "vuedraggable";

export default {
  components: { clientsCharts, sipCall, productData, draggable },
  data() {
    return {
      timer: null,
      showBtnList: false,
      isDivVisible: false,
      callButton: false,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000; // 15天
            let maxTime = this.pickerOneDate + day;
            let minTime = this.pickerOneDate - day;
            return time.getTime() > maxTime || time.getTime() < minTime;
          } else {
            // return time.getTime() > Date.now()
          }
        },
      },
      cutIndex: 3,
      tableDome: [
        {
          label: "归属人",
          prop: "userRealname",
          key: 1,
        },
        {
          label: "企业名称",
          prop: "company",
          key: 2,
        },
        {
          label: "姓名",
          prop: "customerPhone",
          key: 3,
        },
        {
          label: "行业",
          prop: "industry",
          key: 4,
        },
        {
          label: "地址",
          prop: "address",
          key: 5,
        },
        {
          label: "导入时间",
          prop: "createTime",
          key: 6,
        },
        {
          label: "更新时间",
          prop: "getTime",
          key: 7,
        },
        {
          label: "拨打时间",
          prop: "changeTime",
          key: 8,
        },
        {
          label: "备注",
          prop: "remarks",
          key: 9,
        },
        {
          label: "决策人",
          prop: "choosePeople",
          key: 10,
        },
        {
          label: "公司规模",
          prop: "customerScale",
          key: 11,
        },
        {
          label: "客户来源",
          prop: "customerSource",
          key: 12,
        },
      ],
      customArr: [
        {
          label: "归属人",
          prop: "userRealname",
          key: 1,
        },
        {
          label: "企业名称",
          prop: "company",
          key: 2,
        },
        {
          label: "姓名",
          prop: "customerPhone",
          key: 3,
        },
        {
          label: "行业",
          prop: "industry",
          key: 4,
        },
        {
          label: "地址",
          prop: "address",
          key: 5,
        },
        {
          label: "导入时间",
          prop: "createTime",
          key: 6,
        },
        {
          label: "更新时间",
          prop: "getTime",
          key: 7,
        },
        {
          label: "拨打时间",
          prop: "changeTime",
          key: 8,
        },
        {
          label: "备注",
          prop: "remarks",
          key: 9,
        },
        {
          label: "决策人",
          prop: "choosePeople",
          key: 10,
        },
        {
          label: "公司规模",
          prop: "customerScale",
          key: 11,
        },
        {
          label: "客户来源",
          prop: "customerSource",
          key: 12,
        },
      ],
      customColumnsData: [
        {
          label: "归属人",
          prop: "userRealname",
          key: 1,
        },
        {
          label: "企业名称",
          prop: "company",
          key: 2,
        },
        {
          label: "姓名",
          prop: "customerPhone",
          key: 3,
        },
        {
          label: "行业",
          prop: "industry",
          key: 4,
        },
        {
          label: "地址",
          prop: "address",
          key: 5,
        },
        {
          label: "导入时间",
          prop: "createTime",
          key: 6,
        },
        {
          label: "更新时间",
          prop: "getTime",
          key: 7,
        },
        {
          label: "拨打时间",
          prop: "changeTime",
          key: 8,
        },
        {
          label: "备注",
          prop: "remarks",
          key: 9,
        },
        {
          label: "决策人",
          prop: "choosePeople",
          key: 10,
        },
        {
          label: "公司规模",
          prop: "customerScale",
          key: 11,
        },
        {
          label: "客户来源",
          prop: "customerSource",
          key: 12,
        },
      ],
      customColumnsValue: [],
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      soundTableDate: null,
      allPhoneActivePhone: null,
      allPhoneTableData: [],
      allNumShow: false,
      customColumnsShow: false,
      customColumnsHead: false,
      numberTest: false,
      JDoptions: [
        "初步沟通(10%)",
        "方案报价(50%)",
        "谈判协商(80%)",
        "赢单(100%)",
        "输单(0%)",
        "无效",
      ],
      showTitleText: false,
      carry: { phoneTest: "检测" },
      openedArr: [],
      openedArrObj: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      },
      typeList: [
        {
          id: 0,
          title: "外卖/生鲜/商超/电商订单配送",
        },
        {
          id: 1,
          title: "快递/物流派送",
        },
        {
          id: 2,
          title: "家政回访服务",
        },
        {
          id: 3,
          title: "会员关怀/居民服务回访/市场问卷调研/民生保障服务",
        },
        {
          id: 4,
          title: "出行服务",
        },
        {
          id: 5,
          title: "办公协作",
        },
        {
          id: 6,
          title: "政采对接",
        },
        {
          id: 7,
          title: "订单类售后服务",
        },
        {
          id: 8,
          title: "法律服务",
        },
        {
          id: 9,
          title: "广告传媒",
        },
        {
          id: 10,
          title: "生物科技",
        },
        {
          id: 11,
          title: "科研技术类",
        },
        {
          id: 12,
          title: "农林牧渔",
        },
        {
          id: 13,
          title: "包装材料类",
        },
        {
          id: 14,
          title: "服装服饰类",
        },
        {
          id: 15,
          title: "化工制造类/推广",
        },
        {
          id: 16,
          title: "一手交通运输工具类",
        },
        {
          id: 17,
          title: "软/硬件服务类",
        },
        {
          id: 18,
          title: "机械机电设备类",
        },
        {
          id: 19,
          title: "汽车维修/车检/保养等",
        },
        {
          id: 20,
          title: "电商回访类",
        },
        {
          id: 21,
          title: "人力资源回访类",
        },
        {
          id: 22,
          title: "少儿素质教育类",
        },
        {
          id: 23,
          title: "生活摄影",
        },
        {
          id: 24,
          title: "品牌招商加盟",
        },
        {
          id: 25,
          title: "园区招商",
        },
        {
          id: 26,
          title: "网站建设/网站推广",
        },
        {
          id: 27,
          title: "再生资源回收",
        },
        {
          id: 28,
          title: "体育用品",
        },
        {
          id: 29,
          title: "办公文教",
        },
        {
          id: 30,
          title: "日用百货销售",
        },
        {
          id: 31,
          title: "人身保险/车险续保",
        },
        {
          id: 32,
          title: "非房屋租赁服务",
        },
        {
          id: 33,
          title:
            "头部外卖平台/网约车平台/线上商超平台/引擎搜索平台/电商平台/音视频平台/新媒体平台/保险平台自有业务服务",
        },
        {
          id: 34,
          title: "家装建材",
        },
        {
          id: 35,
          title: "财税服务",
        },
        {
          id: 36,
          title: "知识产权",
        },
        {
          id: 37,
          title: "工商代办",
        },
        {
          id: 38,
          title: "平台引流/入驻",
        },
        {
          id: 39,
          title: "成人教育/培训类",
        },
        {
          id: 40,
          title: "房地产",
        },
        {
          id: 41,
          title: "会展/展览",
        },
        {
          id: 42,
          title: "学校招生（直招）",
        },
        {
          id: 43,
          title: "二手车/房推广",
        },
        {
          id: 44,
          title: "商铺推广",
        },
        {
          id: 45,
          title: "旅游服务",
        },
        {
          id: 46,
          title: "酒店民宿",
        },
        {
          id: 47,
          title: "K12教育培训/招生",
        },
        {
          id: 48,
          title: "少儿类兴趣培训",
        },
        {
          id: 49,
          title: "资质代办",
        },
        {
          id: 50,
          title: "钢材招商",
        },
        {
          id: 51,
          title: "企业培训",
        },
        {
          id: 52,
          title: "网站推广/加盟",
        },
        {
          id: 53,
          title: "职称评级",
        },
        {
          id: 54,
          title: "餐饮招商",
        },
      ],
      circuitTypeList: [
        {
          id: 0,
          title: "移动回呼",
        },
        {
          id: 1,
          title: "电信回呼",
        },
        {
          id: 2,
          title: "AXB",
        },
        {
          id: 3,
          title: "SIP",
        },
      ],
      productTypeList: [
        {
          id: 0,
          title: "SAAS",
        },
        {
          id: 1,
          title: "PASS",
        },
        {
          id: 2,
          title: "部署",
        },
      ],
      businessTypeList: [
        {
          id: 0,
          title: "新开户",
        },
        {
          id: 1,
          title: "加坐席",
        },
        {
          id: 2,
          title: "续费",
        },
        {
          id: 3,
          title: "测试",
        },
      ],
      ifParentId: null,
      sjyForm: {
        // contractMoney: 0,
      },

      discountPriceList: [
        {
          id: 0,
          title: "无",
          value: 1,
        },
        {
          id: 1,
          title: "一折",
          value: 0.1,
        },
        {
          id: 2,
          title: "二折",
          value: 0.2,
        },
        {
          id: 3,
          title: "三折",
          value: 0.3,
        },
        {
          id: 4,
          title: "四折",
          value: 0.4,
        },
        {
          id: 5,
          title: "五折",
          value: 0.5,
        },
        {
          id: 6,
          title: "六折",
          value: 0.6,
        },
        {
          id: 7,
          title: "七折",
          value: 0.7,
        },
        {
          id: 8,
          title: "八折",
          value: 0.8,
        },
        {
          id: 9,
          title: "九折",
          value: 0.9,
        },
      ],
      cpVisible: false,
      sellingPrice: 0,
      discountPrice: null,
      num: 0,
      multipleSelection: [],
      cpData: [],
      // clientNameList: [],
      // userList: [],
      contractForm: {
        contractMoney: 0,
      },

      ybkh_status: 0,
      yxkh_status: 0,
      wyxkh_status: 0,
      cjkh_status: 0,
      hmd_status: 0,
      ua: "",
      callData: "", // 拨号后接口数据
      sipCallVisible: false,
      dataStatus: {
        status: 0,
        status1: 0,
        status2: 0,
        status3: 0,
        status4: 0,
      },
      numberList: [
        {
          id: 0,
          total: 0,
          name: "status",
          title: "一般客户",
        },
        {
          id: 1,
          total: 0,
          name: "status1",
          title: "意向客户",
        },
        {
          id: 2,
          total: 0,
          name: "status2",
          title: "无意向客户",
        },
        {
          id: 3,
          total: 0,
          name: "status3",
          title: "成交客户",
        },
        {
          id: 4,
          total: 0,
          name: "status4",
          title: "黑名单",
        },
      ],
      currentAudio: "",
      recordUrl: "",
      Showaudio: false,
      activeData: "cjkh",
      wrapVisible: false,
      activeName: "update",
      update_status: null,
      fileList: [],
      file: {},
      depVisible: false,
      depVisibleBatch: false,
      disabled1: true,
      disabled2: true,
      addVisible: false,
      options3: [
        {
          value: 0,
          label: "a",
        },
        {
          value: 1,
          label: "b",
        },
      ],
      options2: [
        {
          value: 0,
          label: "部门公海",
        },
        {
          value: 1,
          label: "公司公海",
        },
      ],
      currentInfo: null,
      textarea: "",
      drawer: false,
      direction: "rtl",
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      createTime: null,
      changeTime: null,
      getTime: null,
      removeIndex: [],
      callNum: null,
      sipPhone: null,
      industry: null,
      address: null,
      id: null,
      id1: null,
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      disabled: false,
      removeParams: {}, //释放列表时需要的参数
      removeValue: "", // 释放选择框选中的项
      batchRemoveValue: "", // 批量释放选择框选中的项
      removeVisible: false, //释放弹出框
      batchRemoveVisible: false, //批量释放弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: true,
      departId: null,
      userId: null,
      departShow: true,
      upRecords: [],
      dataList: [],
      customerPhone: null,
      form: {
        phone: null,
        keqing_remarks: "",
        historyRecord: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        sendTime: "",
        activePhone: null,
        customerPhone: "",
        company: null,
        departName: null,
        remarks: "",
        industry: null,
        address: null,
        status: "",
        userId: null,
        departId: "",
        id: "",
        id1: null,
        roleId: "",
      },
      notes: null,
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      freeDepart: [],
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      ms_username: null,
      xphone: null,
      times: 20,
      callVisible: false,
      onecallVisible: false,
      historyRecord: null,
      historys: true,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      newColumnsData: [],
      editColumnsData: [],
      customerSourceOptions: [],
    };
  },
  created() {
    // this.clientList();
    // this.fondUserList();
    this.$nextTick(function () {
      this.customerSourceOptions = JSON.parse(
        localStorage.getItem("customerSourceOptions")
      );
      this.setCustomDiv();
      this.newColumnsData = JSON.parse(this.getCookie("columnsData"));
      // this.newColumnsData = JSON.parse(localStorage.getItem("newColumnsData"));
      let propArr = [
        "customerPhone",
        "company",
        "choosePeople",
        "customerScale",
        "customerSource",
        "industry",
        "address",
      ];
      this.editColumnsData = this.newColumnsData.filter((v) => {
        return propArr.includes(v.prop);
      });

      // console.log("newColumnsData", this.newColumnsData);
      this.customColumnsValue = [];
      this.customColumnsData.forEach((v1) => {
        if (this.newColumnsData.some((v2) => v2.key == v1.key)) {
          // console.log("vvv", v1);
        } else {
          this.customColumnsValue.push(v1.key);
        }
      });

      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      // console.log("hideNum参数", this.hideNum);
    });
    this.realname = localStorage.getItem("realname");
    this.ifParentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.ms_username = localStorage.getItem("ms_username");
    this.id = localStorage.getItem("id") * 1;

    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
    }
    if (this.roleName == "组长") {
      this.findUser1();
      this.id1 = localStorage.getItem("id") * 1;
    }
    this.getList();
  },
  //切换界面就执行方法
  // activated() {
  //   this.getList();
  //   this.findUser();
  //   this.findUser1();
  // },
  watch: {
    departUser() {
      this.departId = null;
      this.userId = null;
    },

    openedArrObj: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  computed: {},
  inject: ["reload"],
  methods: {
    getCookie(name) {
      const cookieArr = document.cookie.split("; ");
      for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if (cookiePair[0] === name) {
          return cookiePair[1];
        }
      }
      return JSON.stringify([]);
    },
    getNewLabel(oldLabel, aprop) {
      // console.log("this.newColumnsData", this.newColumnsData);
      let name = oldLabel;
      this.newColumnsData.forEach((v) => {
        if (v.prop == aprop) {
          name = v.label;
        }
      });
      return name;
    },
    setisDivVisible(bool) {
      if (!bool) {
        this.industry = null;
        this.customerSource = null;
        this.id1 = null;
        this.departId = null;
        this.userId = null;
        this.createTime = null;
      }
      this.isDivVisible = bool;
    },
    goBack() {
      this.$router.go(-1);
    },
    draggableChange(list) {
      console.log("list", list);
      console.log("customColumnsData", this.customColumnsData);
    },
    openCustom() {
      this.customColumnsHead = true;
    },
    async setCustomDiv() {
      let params = {
        // parentId: this.ifParentId
        // nameList
      };
      if (this.roleName == "企业") {
        params.parentId = this.id;
      } else {
        params.parentId = this.ifParentId;
      }

      let res = await customFindOne(params);
      let data = res.data.data;
      this.customColumnsData = [
        {
          label: "归属人",
          prop: "userRealname",
          key: 1,
        },
        {
          label: "企业名称",
          prop: "company",
          key: 2,
        },
        {
          label: "姓名",
          prop: "customerPhone",
          key: 3,
        },
        {
          label: "行业",
          prop: "industry",
          key: 4,
        },
        {
          label: "地址",
          prop: "address",
          key: 5,
        },
        {
          label: "导入时间",
          prop: "createTime",
          key: 6,
        },
        {
          label: "更新时间",
          prop: "getTime",
          key: 7,
        },
        {
          label: "拨打时间",
          prop: "changeTime",
          key: 8,
        },
        {
          label: "备注",
          prop: "remarks",
          key: 9,
        },
        {
          label: "决策人",
          prop: "choosePeople",
          key: 10,
        },
        {
          label: "公司规模",
          prop: "customerScale",
          key: 11,
        },
        {
          label: "客户来源",
          prop: "customerSource",
          key: 12,
        },
      ];
      this.customColumnsData.forEach((v1) => {
        data.forEach((v2) => {
          if (v2.name == v1.label) {
            v1.label = v2.value;
          }
        });
      });
      this.customArr.forEach((v1) => {
        data.forEach((v2) => {
          if (v2.name == v1.label) {
            v1.value = v2.value;
            v1.id = v2.id;
            v1.name = v2.name;
            v1.parentId = v2.parentId;

            // v1 = Object.assign(v2, v1)
          }
        });
      });
      this.newColumnsData = this.customColumnsData.filter(
        (v) => !this.customColumnsValue.includes(v.key)
      );
      let propArr = [
        "customerPhone",
        "company",
        "choosePeople",
        "customerScale",
        "customerSource",
        "industry",
        "address",
      ];
      this.editColumnsData = this.newColumnsData.filter((v) => {
        return propArr.includes(v.prop);
      });

      // localStorage.setItem("newColumnsData", JSON.stringify(this.newColumnsData));
      await this.saveNewColumnsData();
    },

    async setCustomHeadData(item, value) {
      // console.log("item", !item.parentId);
      let params;
      if (!item.parentId) {
        params = {
          name: item.label,
          value,
        };
        if (this.roleName == "企业") {
          params.parentId = this.id;
        } else {
          params.parentId = this.ifParentId;
        }
      } else {
        params = {
          parentId: item.parentId,
          name: item.name,
          value,
        };
      }
      let res = await customAddUpdate(params);
      if (res.data.statusCode == "00000") {
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
    },
    // 还原自定义标签
    async delCustomHeadData(item) {
      console.log("删除的列", item);
      let params = {
        id: item.id,
      };
      let res = await customDel(params);
      if (res.data.statusCode == "00000") {
        this.customArr.forEach((v) => {
          if (v.prop == item.prop) {
            v.value = null;
          }
        });
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
      }
    },
    getCustom(item) {
      let arr = [
        "userRealname",
        "createTime",
        "getTime",
        "changeTime",
        "customerSource",
      ];
      return !arr.includes(item.prop);
    },
    setCustomColumns(item) {
      let configuration = {
        confirmButtonText: "确定",
        cancelButtonText: "删除设置",
        inputValue: item.value,
        distinguishCancelAndClose: true, // 重要，设置为true才会把右上角X和取消区分开来
      };
      if (!item.id) {
        configuration.showCancelButton = false;
      }
      this.$prompt("请输入新表头名称", "修改", configuration)
        .then(async ({ value }) => {
          if (!value) {
            Message.error("设置失败,表头名称不能为空");
            return false;
          }
          await this.setCustomHeadData(item, value);
          await this.setCustomDiv();
          // await this.reset()
        })
        .catch(async (action, instance, done) => {
          if (action === "cancel") {
            await this.delCustomHeadData(item);
            await this.setCustomDiv();
            // await this.reset()
          }
        });
    },

    logAd() {
      console.log("this.customColumnsData", this.customColumnsData);
    },
    // 绑定指令
    directives: {
      draggable: {
        bind(el, binding) {
          let startX, startY, initialMouseX, initialMouseY, dragElement;
          let { index, list } = binding.value;
          el.addEventListener("mousedown", (e) => {
            startX = el.offsetLeft;
            startY = el.offsetTop;
            initialMouseX = e.clientX;
            initialMouseY = e.clientY;
            dragElement = el;
            dragElement.style.zIndex = 999;
            e.stopPropagation();
          });
          document.addEventListener("mousemove", (e) => {
            if (dragElement) {
              dragElement.style.left = `${
                startX + e.clientX - initialMouseX
              }px`;
              dragElement.style.top = `${startY + e.clientY - initialMouseY}px`;
              let newIndex = Math.round(
                (startX + e.clientX - initialMouseX) / 100
              );
              newIndex =
                newIndex < 0
                  ? 0
                  : newIndex > list.length - 1
                  ? list.length - 1
                  : newIndex;
              if (newIndex !== index) {
                list.splice(newIndex, 0, list.splice(index, 1)[0]);
              }
            }
          });
          document.addEventListener("mouseup", () => {
            if (dragElement) {
              dragElement.style.zIndex = "";
              dragElement = null;
            }
          });
        },
      },
    },
    saveNewColumnsData() {
      const columnsData = this.newColumnsData;
      const jsonStr = JSON.stringify(columnsData);
      const futureDate = new Date();
      futureDate.setFullYear(futureDate.getFullYear() + 10); // 设置为未来10年
      document.cookie = `columnsData=${jsonStr}; expires=${futureDate.toUTCString()}; path=/`;
    },
    customColumnsChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
      // console.log("this.customColumnsValue",this.customColumnsValue);
      this.newColumnsData = this.customColumnsData.filter(
        (v) => !this.customColumnsValue.includes(v.key)
      );
      // localStorage.setItem("newColumnsData", JSON.stringify(this.newColumnsData));
      this.saveNewColumnsData();

      if (direction == "right") {
        Message.success(`增加${movedKeys.length}条隐藏列`);
      } else {
        Message.success(`增加${movedKeys.length}条显示列`);
      }

      // console.log("this.newColumnsData", this.newColumnsData);
    },
    async beforeAvatarUpload(file) {
      console.log("导入前限制大小");
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
        return isLt2M;
      }
      // return isJPG && isLt2M;

      console.log("导入前限制大小001", XlsxPopulate);

      const workbook = await XlsxPopulate.fromDataAsync(file);
      const sheet = workbook.sheet(0); // 假设要校验第一个 sheet
      console.log("workbook.sheet", workbook.sheet);
      // 根据需要自定义期望的表头
      const expectedHeaders = ["Header 1", "Header 2", "Header 3"];

      const headers = [];
      const range = sheet.usedRange();

      range.value().forEach((row, rowIndex) => {
        if (rowIndex === 0) {
          // 第一行是表头
          headers.push(...row);
        }
      });

      // 校验表头
      const isValid = expectedHeaders.every((expectedHeader) =>
        headers.includes(expectedHeader)
      );

      if (isValid) {
        // 表头校验通过
        console.log("表头校验通过");
        // 继续上传文件...
        return true;
      } else {
        // 表头校验失败
        console.log("表头校验失败");
        // 取消上传文件...
        return false;
      }
      return false;
    },
    startaudio(row) {
      this.recordUrl = row.row.recordUrl;
      if (
        row.row.recordUrl == "略" ||
        row.row.recordUrl == "" ||
        row.row.recordUrl == null
      ) {
        Message.warning("暂无音频");
      } else {
        this.currentAudio = row.row.recordUrl;
        // console.log(row.row);
        this.Showaudio = true;
      }
    },
    // 筛选禁止复制的公司
    filterEnterprise() {
      // let arr = [2]
      let arr = [5805];
      if (arr.includes(localStorage.getItem("parentId") * 1)) {
        return "no-select";
      }
    },
    async getHideNum() {
      let params = {
        parentId: localStorage.getItem("parentId"),
      };
      let res = await list(params);
      if (res.data) {
      } else {
        return false;
      }
      console.log("号码检测是否开通", res.data);
    },
    // 导入号码的关闭事件
    clearNumber() {
      this.fileList = [];
      this.file = {};
      this.$refs.upload.clearFiles();
    },
    // 新增或修改的提交
    async onSubmit() {
      const loading = await this.$loading({
        lock: true,
        text: "添加商机中,请稍等...",
      });
      let params;
      let res;
      console.log("this.form", this.form);
      params = {
        clentId: this.form.id,
        clentName: this.form.customerPhone,
        money: this.form.money,
        name: this.form.name,
        predictMakeTime: this.form.predictMakeTime,
        remarke: this.form.remarke,
        shangjiStage: this.form.shangjiStage,
      };
      res = await add(params);

      if (res.data.statusCode == "00000") {
        loading.close();
        this.wrapVisible = false;
        Message.success(res.data.message);
      } else {
        Message.error(res.data.message);
        loading.close();
      }
    },
    // 合计的处理
    formatter(row, column) {
      // console.log("合计处理", row);
      let sum = row.sellingPrice * row.discountPrice * row.num;
      row.sumMoney = sum.toFixed(2);
      return sum.toFixed(2);
    },
    // 移除
    del(row) {
      // console.log("this.cpData", this.cpData);
      this.$confirm("是否移除该产品", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.cpData.forEach((v, i) => {
            if (v.id == row.id) {
              this.cpData.splice(i, 1);
            }
            Message.success("已移除");
          });
        })
        .catch(() => {
          Message.info("已取消删除");
        });
    },
    // 获取添加的产品信息
    getTableData(val) {
      this.cpData = val.multipleSelection;
      this.cpVisible = val.bool;
      console.log("获取产品信息");
    },
    handleConfirm(v) {
      this.multipleSelection = v;
    },
    // 添加产品
    addCP() {
      this.cpVisible = true;
    },
    // 新增合同
    contractSubmit() {
      let params = {
        bsf: 7,
        // 合同
        contractName: this.contractForm.contractName,
        clientName: this.contractForm.clientName,
        clientId: this.contractForm.clientId,
        companyUser: this.contractForm.companyUser,
        companyUserId: this.contractForm.companyUserId,
        companyName: this.contractForm.companyName,
        orderDate: this.contractForm.orderDate
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.contractForm.orderDate)
          : null,
        contractMoney: this.contractForm.contractMoney,
        remark: this.contractForm.remark,
      };
      params.contractPriceList = this.cpData.map((v) => {
        return {
          productName: v.productName, // 产品名字
          unit: v.unit, // 产品价格
          sellingPrice: v.sellingPrice, // 售价
          discountPrice: v.discountPrice, // 折扣
          num: v.num, // 数量
          sumMoney: v.sumMoney, // 合计
        };
      });
      let res = contractAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          // 新增合同后创建回款
          this.createHuiKuan(res.data.data);
          this.wrapVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.getList();
        }
      });
    },
    // 创建合同后创建回款
    async createHuiKuan(contract) {
      let params = {
        bsf: "6",
        clientName: this.contractForm.companyName, // 公司名称
        clientId: this.contractForm.clientId,
        contractNumber: contract.contractNumber,
        contractId: contract.id,
        moneyWay: "无",
        remark: null,
        money: 0,
        periods: 0,
      };

      console.log("回款params", params);
      let res = await addCollection(params);
      console.log("回款res", res);
    },
    // 新增合同--商机云
    sjySubmit() {
      if (this.ifParentId == 7) {
        if (!this.form.customerSource) {
          Message.warning("需要先去设置客户来源");
          return false;
        }
      }
      if (this.sjyForm.installment) {
        if (this.sjyForm.installment == "1") {
          this.sjyInstallment = "是";
        } else {
          this.sjyInstallment = "否";
        }
      }
      let params = {
        bsf: 7,
        // 合同
        companyName: this.sjyForm.companyName,
        customerSource: this.sjyForm.customerSource,
        whole: this.sjyForm.whole,
        type: this.sjyForm.type,
        phone: this.sjyForm.phone,
        telephone: this.sjyForm.telephone,
        businessHandling: this.sjyForm.businessHandling,
        businessType: this.sjyForm.businessType,
        routeType: this.sjyForm.routeType,
        numberFee: this.sjyForm.numberFee,
        numberNum: this.sjyForm.numberNum,
        combo: this.sjyForm.combo,
        seatsNumber: this.sjyForm.seatsNumber,
        rateWithin: this.sjyForm.rateWithin,
        rateExtra: this.sjyForm.rateExtra,
        cooperationTime: this.sjyForm.cooperationTime,
        comboRecharge: this.sjyForm.comboRecharge,
        featureFee: this.sjyForm.featureFee,
        feeTotal: this.sjyForm.feeTotal || 0,
        presented: this.sjyForm.presented,
        installment: this.sjyInstallment,
        remarke: this.sjyForm.remarke,
        clientId: this.sjyForm.clientId,
      };
      let res = shangjiContractShangjiAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          // 新增合同后创建回款
          this.createHuiKuan(res.data.data);
          this.wrapVisible = false;
          this.getList();
        } else {
          Message.error(res.data.message);
          this.wrapVisible = false;
          this.getList();
        }
      });
    },

    // 挂断
    hang_up() {
      if (this.ua) {
        this.ua.stop();
      }
      this.wrapVisible = true;
    },
    // sip挂断关闭弹框
    showMethod(obj) {
      this.sipCallVisible = obj.bool;
      this.ua = obj.ua;
    },
    // 编辑关闭回调
    handleClose(done) {
      this.$confirm("如需关闭弹框，请挂断SIP通话")
        .then((_) => {
          // done();
        })
        .catch((_) => {});
    },
    // 双击事件
    dblclick(row) {
      this.getSoundList(row.activePhone);
      // console.log("row", row);
      this.wrapVisible = true;
      this.form = { ...row };
      this.update_status = null;
      this.activeName = "update";
      // contractForm
      this.contractForm.clientName = row.customerPhone;
      this.contractForm.clientId = row.id;
      this.contractForm.companyUser = row.userRealname;
      this.contractForm.companyUserId = row.userId;
      this.contractForm.companyName = row.company;
      // sjyForm
      this.sjyForm = {};
      this.sjyForm.companyName = row.company;
      this.sjyForm.customerSource = row.customerSource;
      this.sjyForm.clientId = row.id;
    },
    // soundTableDate
    async getSoundList(activePhone) {
      let params = {
        page: 1,
        size: 100,
        // userId: this.selectId,
        passivePhone: activePhone, //根据被叫查
        // callStarttime: this.callStarttime,
        // callEndtime: this.callEndtime,
        // activePhone: this.activePhone, //根据主叫查
        // second: this.second,
      };
      let res = await callLogList(params);
      this.soundTableDate = res.data.data;
      // console.log("res666", res.data.data);
    },
    // 双击切换事件
    handleClick(e) {
      if (e.index == "2") {
        this.record();
      } else if (e.index == "3") {
        this.getPlan();
      }
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 查询是否开通短信服务
    submitaddValue() {
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      let params = { parentId };
      let res = userSmsFindOne(params);
      res.then((res) => {
        if (res.data.data != null) {
          // 可用
          this.addVisible = true;
        } else {
          // 不可用
          Message.success("开通短信服务后可用");
        }
      });
    },
    // 短信提醒跟进
    addBtn(row) {
      // this.addVisible = true;
      // console.log("-----", row.row);
      this.form.userId = row.row.userId;
      this.form.activePhone = row.row.activePhone;
      this.form.customerPhone = row.row.customerPhone;
      this.form.sendTime = row.row.sendTime;
      this.form.keqing_remarks = row.row.keqing_remarks;
      this.submitaddValue();
    },
    // 短信提醒跟进确定
    submitaddValueS() {
      let sendTime = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.form.sendTime);
      let params = {
        activePhone: this.form.activePhone,
        sendTime,
        userId: this.form.userId,
        name: this.form.customerPhone,
        remarks: this.form.keqing_remarks,
      };
      let res = reminderAdd(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.addVisible = false;
        this.getList();
      });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 关闭历史记录时
    handleCloseRecord() {
      this.drawer = false;
      this.upRecords = [];
      this.dataList = [];
    },
    // 根进记录
    async record() {
      let params = {
        phoneId: this.form.id,
      };
      let res = await phoneFindOneHistoryPhone(params);
      let data = res.data.data;
      this.upRecords = [];

      if (data.historyRecord == "") {
        data.historyRecord.split("").forEach((v) => {
          this.upRecords.push(this.initArr(v));
        });
      } else {
        data.historyRecord.forEach((v) => {
          this.upRecords.push(this.initArr(v));
        });
      }
      this.drawer = true;
    },
    // 根进计划
    async getPlan() {
      let params = {
        userId: this.form.id,
        page: "1",
        size: "500",
      };
      let res = await phoneFindOnePlanPhone(params);
      console.log("res", res);
      let data = res.data.data;
      data ? (this.dataList = data) : (this.dataList = []);

      // if (data.historyRecord == "") {
      //   data.historyRecord.split("").forEach((v) => {
      //     this.dataList.push(this.initArr(v));
      //   });
      // } else {
      //   data.historyRecord.forEach((v) => {
      //     this.dataList.push(this.initArr(v));
      //   });
      // }
    },
    // 将跟进历史数据格式化
    initArr(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -20),
      };
      return obj;
    },
    // 将归属人数据格式化
    initArr1(str) {
      let obj = {
        data: str.substr(-19),
        content: str.slice(0, -22),
      };
      return obj;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray);
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    //释放具体部门
    findUser2() {
      // let gsId = localStorage.getItem("id") * 1;
      let bmId = localStorage.getItem("parentId") * 1;
      let params = {
        topId: "",
      };
      if (this.roleName == "公司") {
        // params.topId = gsId;
      } else {
        params.topId = bmId;
      }
      let res = sysDepartList(params);
      res.then((res) => {
        let data = res.data.data;
        for (let key in data) {
          this.depId = data[key].id;
          this.freeDepart = data;
        }
      });
    },
    findUser1() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },
    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },
    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },
    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },
    // 全局查找
    allHandleSearch() {
      this.allNumShow = true;
    },
    //全局搜索
    async allPhoneHandleSearch() {
      // allPhoneActivePhone
      let params = {
        activePhone: this.allPhoneActivePhone,
      };
      let res = await findOne_all(params);
      this.allPhoneTableData = [];
      this.allPhoneTableData.push(res.data.data);
    },
    //搜索
    handleSearch() {
      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      let params = {};
      this.page = 1;
      // let status = 1;
      // console.log(123456789, this.cutIndex);
      // if (!this.cutIndex) {
      //   this.cutIndex = 0;
      // }
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          industry: this.industry,
          address: this.address,
          remarks: this.notes,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      } else {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          userId: this.id1,
          industry: this.industry,
          address: this.address,
          activePhone: this.activePhone,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          remarks: this.notes,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      }
      // let res1 = phoneFindUser({});
      // console.log(123456789, params);
      // res1.then((b) => {
      //   this.underDepart = b.data.data.depart;
      //   this.underUser = b.data.data.user;
      // });
      if (!params.activePhone) params.activePhone = null;
      let res = phoneList(params);
      res.then((a) => {
        let data = a.data;
        if (data.statusCode == "50000") {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
          return false;
        }
        this.openedArr = [];
        if (data.data) {
          this.openedArrObj[this.cutIndex] = data.data;
          this.pageTotal = data.total;
        }
        a.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    //重置
    async reset() {
      this.notes = null;
      // this.reload();
      this.activePhone = null;
      this.id1 = null;
      this.userId = null;
      this.company = null;
      this.industry = null;
      this.customerSource = null;
      this.departId = null;
      this.createTime = null;
      await this.getList();
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },
    //分配号码确认提交
    async distributeSubmit() {
      Message.warning("分配号码中,请稍等...");
      if (this.userId == "" || this.userId == null) {
        let params = {
          listId: this.numsArray,
          departId: this.departId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
        } else {
          Message.error(res.data.message);
        }
      } else {
        let params = {
          listId: this.numsArray,
          userId: this.userId,
        };
        let res = await phoneDistribute(params);
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.numsArray = [];
        } else {
          Message.error(res.data.message);
          this.numsArray = [];
        }
      }
      this.distributeShow = false;
      this.userId = null;
      this.departId = null;
      this.getList();
    },
    // 展示
    getList(form) {
      let params = {};
      // if (form != '跳页') {
      if (this.cutIndex) {
        this.cutStatus = this.cutIndex;
      } else if (!this.cutIndex) {
        this.cutStatus = 0;
      }
      // }
      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      // let status = 1;
      // console.log(123456789, this.cutIndex);
      // if (!this.cutIndex) {
      //   this.cutIndex = 0;
      // }
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          industry: this.industry,
          address: this.address,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      } else {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          userId: this.id1,
          industry: this.industry,
          address: this.address,
          activePhone: this.activePhone,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      }
      if (!params.activePhone) params.activePhone = null;
      let res = phoneList(params);
      res.then((res) => {
        let data = res.data;
        if (data.statusCode == "50000") {
          // this.cutIndex = oldIndex
          // data = res.data.data
          Message.warning(data.message);
        }
        this.dataStatus = {
          status: res.data.status,
          status1: res.data.status1,
          status2: res.data.status2,
          status3: res.data.status3,
          status4: res.data.status4,
        };
        // 客户分类总数
        // this.ybkh_status = res.data.status;
        // this.yxkh_status = res.data.status1;
        // this.wyxkh_status = res.data.status2;
        // this.cjkh_status = res.data.status3;
        // this.hmd_status = res.data.status4;
        // this.tableData = res.data.data;
        if (res.data.data) {
          this.openedArrObj[this.cutIndex] = res.data.data;
          this.pageTotal = res.data.total;
        }

        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    // 展示切换
    async clickData(e) {
      let oldIndex = this.cutIndex;
      switch (e.index) {
        case "0":
          this.cutIndex = "3";
          // 当expression的值等于value1时执行的代码块
          break;
        case "2":
          this.cutIndex = "0";
          // 当expression的值等于value2时执行的代码块
          break;
        case "3":
          this.cutIndex = "2";
          // 当expression的值等于value2时执行的代码块
          break;
        // 可以有更多的case语句
        default:
          this.cutIndex = e.index;
          // 当expression的值不匹配任何case时执行的代码块
          break;
      }

      if (this.openedArr.includes(e.index)) {
        this.pageTotal = this.openedArrObj[this.cutIndex].pageTotal;
        return false;
      }

      // 转换日期
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }

      let params = {};
      this.page = 1;
      // let status = 1;
      // console.log(123456789, this.cutIndex);
      // if (!this.cutIndex) {
      //   this.cutIndex = 0;
      // }
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          activePhone: this.activePhone,
          userId: this.userId,
          depart_id: this.departId,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          industry: this.industry,
          address: this.address,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      } else {
        params = {
          // bsf: 1,
          status: this.cutIndex,
          page: this.page,
          size: this.size,
          userId: this.id1,
          industry: this.industry,
          address: this.address,
          activePhone: this.activePhone,
          company: this.company,
          sta_createTime: sta_createTime,
          end_createTime: end_createTime,
          sta_changeTime: sta_changeTime,
          end_changeTime: end_changeTime,
          sta_getTime: sta_getTime,
          end_getTime: end_getTime,
          // choosePeople: this.choosePeople,
          // customerScale: this.customerScale,
          customerSource: this.customerSource,
          customerPhone: this.customerPhone,
        };
      }
      // let res1 = phoneFindUser({});
      // console.log(123456789, params);
      // res1.then((b) => {
      //   this.underDepart = b.data.data.depart;
      //   this.underUser = b.data.data.user;
      // });
      if (this.roleName == "管理员" || this.roleName == "企业") {
        params.depart_id = this.departId;
      } else {
        params.userId = this.id1;
      }

      params.status = this.cutIndex;
      if (!params.activePhone) params.activePhone = null;
      let res = await phoneList(params);

      console.log("res", res.data);
      let data = res.data;
      if (data.statusCode == "50000") {
        // this.cutIndex = oldIndex
        // data = res.data.data
        Message.warning(data.message);
      }
      this.currentInfo = data;
      // this.openedArrObj[this.cutIndex] = data.data;
      // this.$set(this, this.openedArrObj[this.cutIndex], data.data)

      if (data.data) {
        this.openedArrObj[this.cutIndex] = data.data;
        this.openedArrObj[this.cutIndex].pageTotal = data.total;
        this.pageTotal = data.total;
      }

      this.openedArr.push(e.index);

      data.data.forEach((item) => {
        let str = item.activePhone;
        item.phone = str.substring(0, 3) + "****" + str.substring(7);
      });
    },
    async yesCall(row) {
      if (this.callButton) {
        Message.warning("请稍等几秒再拨打~");
        return false;
      }

      // const loading = await this.$loading({
      //   lock: true,
      //   text: "即将拨打,请稍等...",
      // });
      // console.log("查看是不是row", row);
      // console.log(12346, row.row);
      this.form = row.row;
      this.update_status = null;
      let parentId = JSON.parse(localStorage.getItem("parentId"));
      this.sipPhone = row.row.activePhone;
      let params = {
        passivePhone: this.sipPhone,
        activePhone: this.ms_username,
        parentId,
        phoneId: row.row.id,
      };
      let res = callAppBind(params);
      console.log("callAppBind", res);
      res.then((res) => {
        this.callData = res;
        if (res.data.statusCode == "00000") {
          // this.wrapVisible = true;
          this.getList();
          // loading.close();
          Message.success(res.data);
          //判断是否是AXB呼叫模式
          if (res.data.data.sip) {
            // console.log("sipsip");
            this.sipCallVisible = true;
            // console.log("res.data.data.sip",res.data.data);
            // this.callNum;
          } else if (res.data != null && !res.data.data.sip) {
            // console.log(res.data.data);
            this.xphone = res.data.data;
            this.callVisible = true;
            this.countTime();
            this.update(row);
          } else {
            Message.success("请注意接听手机电话！");
          }
        } else {
          // loading.close();
          // Message.error(res.data);
          let data = res.data.data;
          if (data) {
            Message.error(JSON.parse(data).message);
          } else {
            Message.error(res.data.message);
          }
        }
        this.callButton = true;
        this.tiemID = setTimeout(() => {
          this.callButton = false;
        }, 5000);
      });
    },
    call(row) {
      if (true) {
        // 现在直接拨打
        this.yesCall(row);
      } else {
        // console.log("当前项号码666", row.row.activePhone);
        this.$confirm("是否使用号码检测?", "提示", {
          confirmButtonText: "使用号码检测",
          cancelButtonText: "直接拨号",
          type: "warning",
          distinguishCancelAndClose: true, // 重要，设置为true才会把右上角X和取消区分开来
          beforeClose: (action, instance, done) => {
            // console.log("action", action);
            if (action === "cancel") {
              this.yesCall(row);
            }
            done();
          },
        })
          .then(async () => {
            let params = {
              phone: row.row.activePhone + "",
            };
            let res = await find(params);
            if (res.data.statusCode == "00000") {
              let status = res.data.data[0].status;
              if (status == 1) {
                Message.success("有效号码");
                await this.yesCall(row);
              } else if (status == 2) {
                Message.error("无效号码");
              } else {
                Message.info("未知");
                await this.yesCall(row);
              }
            } else {
              Message.error(res.data.message);
            }
          })
          .catch(() => {
            // this.yesCall(row);
          });
      }
    },
    //拨打按钮
    callDa(row) {
      // 需要确认有无这些文件导入 (selectDzwlUserApi和confirmUserOnline接口,Message插件)
      //根据用户IP 返回城市级别的定位结果
      let native = new BMap.LocalCity();
      native.get(async (res) => {
        let params = {
          companyId: localStorage.getItem("parentId"),
          userId: localStorage.getItem("id"),
        };
        // 确认在不在白名单
        let res1 = await selectDzwlUserApi(params);
        let data1 = res1.data.data;
        if (data1.length != 0) {
          // 可以拨打的地方
          this.callDa(row);
        } else {
          // 确定用户在不在经纬度接口
          params = {
            userLongitude: res.center.lng, //围栏圆心经度
            userLatitude: res.center.lat, ////围栏圆心纬度
            companyId: localStorage.getItem("parentId"), // 公司id
            userId: localStorage.getItem("id"), // 用户id
          };
          let res2 = await confirmUserOnline(params);
          if (res2.data.statusCode == "00000") {
            // 可以拨打的地方
            this.callDa(row);
          } else {
            Message.warning("你当前的位置不在公司围栏内!");
            return false;
          }
        }
      });
    },
    //axb呼叫模式弹窗
    countTime() {
      if (this.times > 0) {
        setTimeout(() => {
          this.times -= 1;
          this.countTime();
        }, 1000);
      } else {
        this.callVisible = false;
      }
    },
    //编辑按钮
    update(row) {
      this.wrapVisible = true;
      this.form = row.row;
      this.update_status = null;
    },
    // 释放按钮
    removeButton(data, index) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法释放");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法释放");
        return;
      }
      this.removeIndex = index;
      let params = {
        listId: [data.id],
        roleId: data.roleId,
        departId: data.departId,
      };
      this.removeParams = params;
      this.removeVisible = true;
    },
    // 下拉事件
    oneSlt() {
      if (this.removeValue == 0) {
        this.disabled1 = false;
      } else {
      }
    },
    // 批量下拉事件
    oneSltBatch() {
      if (this.batchRemoveValue == 0) {
        this.disabled2 = false;
      } else {
      }
    },
    // 释放 公司
    confirm() {
      // 没有部门id，就去公司公海
      this.removeParams.departId = "";
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.openedArrObj[this.cutIndex].splice(this.removeIndex, 1);
        this.removeVisible = false;
        this.getList();
      });
    },
    // 释放 部门
    step() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.openedArrObj[this.cutIndex].splice(this.removeIndex, 1);
        this.getList();
      });
    },
    // 释放确认事件
    submitRemoveValue() {
      if (this.removeValue === "") return;
      // console.log(this.removeParams.departId);
      // 有部门id，就去部门
      if (this.removeValue === 0) {
        this.step();
      } else if (this.removeValue === 1) {
        this.confirm();
      } else {
      }
    },
    // 查询具体部门
    depDetails() {
      this.depVisible = true;
      this.removeVisible = false;
      this.findUser2();
    },
    // 批量查询具体部门
    depDetails1() {
      this.batchRemoveVisible = false;
      this.depVisibleBatch = true;
      this.findUser2();
    },
    // 批量释放按钮
    moreDelete() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.batchRemoveVisible = true;
    },
    // 释放 公司
    confirm1() {
      let params = {
        listId: this.numsArray,
        roleId: this.openedArrObj[this.cutIndex][0].roleId,
        departId: "",
      };
      this.batchRemoveVisible = false;
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.openedArrObj[this.cutIndex].findIndex(
            (item) => item.id === v
          );
          this.openedArrObj[this.cutIndex].splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 释放 部门
    step1() {
      let params = {
        listId: this.numsArray,
        roleId: this.openedArrObj[this.cutIndex][0].roleId,
        departId: this.departId,
      };
      this.depVisibleBatch = false;
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.openedArrObj[this.cutIndex].findIndex(
            (item) => item.id === v
          );
          this.openedArrObj[this.cutIndex].splice(index, 1);
          // this.numsArray.splice(i, 1);
        });
        this.getList();
      });
    },
    // 批量释放确认事件
    submitBatchRemoveValue() {
      this.numsArray.map((v, i) => {
        this.openedArrObj[this.cutIndex].findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法释放");
            return;
          }
        });
      });
      if (this.batchRemoveValue === "") return;
      if (this.batchRemoveValue === 0) {
        this.step1();
      } else if (this.batchRemoveValue === 1) {
        this.confirm1();
      } else {
      }
      setTimeout(function () {
        location.reload();
      }, 1000);
    },
    //编辑提交
    updateSubmit() {
      if (this.ifParentId == 7) {
        if (!this.form.customerSource) {
          Message.warning("客户来源不能为空~");
          return false;
        }
      }

      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          historyRecord: this.form.historyRecord,
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.openedArr = [];
            this.wrapVisible = false;
            this.getList();
          } else {
            Message.error(res.data.message);
            this.wrapVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      window.open("http://124.70.206.131:9005/phone.xls", "_self");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(`当前限制选择 1 个文件，请勿重复上传`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    compareArrays(arr1, arr2) {
      return JSON.stringify(arr1) === JSON.stringify(arr2);
    },
    changeUpload(file, fileList) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        console.log("jsonData", jsonData);
        // 获取表头行数据
        const headerRow = jsonData[0];
        let titleArr = [
          "公司",
          "客户姓名",
          "手机号",
          "客户分类",
          "备注",
          "坐席账户",
          "部门名字",
          "行业",
          "地址",
        ];
        console.log(
          "headerRow",
          headerRow,
          this.compareArrays(headerRow, titleArr)
        );
        // 根据您的需求编写表头校验逻辑
        if (this.compareArrays(headerRow, titleArr)) {
          this.file = file;
          // resolve();
        } else {
          setTimeout(() => {
            this.file = {};
            this.$refs.uplaod.clearFiles();
          }, 1000);
          this.$message.error("请勿修改表头!");
        }
      };
      reader.onerror = () => {
        this.$message.error("读取文件失败!");
      };
      reader.readAsArrayBuffer(file.raw);
    },
    //批量导入按钮
    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    // 切片
    sliceFile() {
      const chunkSize = 1024 * 8;
      const chunks = [];
      let start = 0;
      while (start < this.file.size) {
        const end = Math.min(start + chunkSize, this.file.size);
        const chunk = this.file.slice(start, end);
        chunks.push(chunk);
        start += chunkSize;
      }
      return chunks;
    },
    //批量导入提交
    async numsSubmit(index) {
      console.log("导入前限制大小", this.file);
      // .raw
      const isLt2M = this.file.size / 1024 / 1024 < 3;

      if (!this.file.size) {
        this.$message.warning("上传的文件不能为空!");
        return false;
      } else if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
        return false;
      }

      console.log("导入前限制大小", XlsxPopulate);
      let formDate = new FormData();
      // 完整上传
      // let formDate = new FormData();
      formDate.append("file", this.file.raw);
      Message.success("数据加载中，稍等！请勿离开页面");

      const loading = await this.$loading({
        lock: true,
        text: "导入号码中,请稍等...",
      });

      if (this.numberTest) {
        formDate.append("phoneTest", 1);
      }
      // phoneImportPhoneOld 旧版
      // phoneImportPhone 新版
      let res;
      if (this.ms_username == "13951513817") {
        res = await phoneImportPhoneOld(formDate);
      } else {
        res = await phoneImportPhone(formDate);
      }
      if (res.data.statusCode == "00000") {
        loading.close();
        Message.success(res.data.message);
        this.file = {};
      } else {
        loading.close();
        Message.error(res.data.message);
        this.file = {};
      }
      this.disabled = true;
      this.numShow = false;
      ///----------->

      // 切片上传
      // let chunks = this.sliceFile()

      // if (index >= chunks.length) {
      //   // 所有块上传完毕，进行下一步操作
      //   console.log("已全部上传成功");
      //   return;
      // }

      // let formData = new FormData();
      // // const file = document.querySelector('input[type="file"]').files[0];
      // // formData.append('file', file);

      // console.log('确认类型', index)
      // console.log('确认类型', chunks[index])
      // formData.append('file', chunks[index], `${this.file.name}.part${index}`);

      // // 发送请求，上传文件块
      // let res = await phoneImportPhone(formData)
      // if (res.data.statusCode == "00000") {
      //   Message.success(res.data.message);
      //   this.numsSubmit(index + 1)
      //   // this.file = {};
      // } else {
      //   Message.error(res.data.message);
      //   // this.file = {};
      // }
    },
    //添加号码
    addNums() {
      this.form = [];
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      if (!this.form.activePhone) {
        Message.warning("客户号码不能为空~");
        return false;
      }
      if (this.ifParentId == 7) {
        if (!this.form.customerSource) {
          Message.warning("客户来源不能为空~");
          return false;
        }
      }

      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        company: this.form.company,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        customerSource: this.form.customerSource,
        industry: this.form.industry,
        address: this.form.address,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.vipSearch {
  // padding: 20px 0;
  // padding-right: 20px;
  // box-sizing: border-box;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  // border-radius: 7px;
}

.head-box {
  cursor: pointer;
  display: inline-block;
  padding: 10px 16px;
  // border: 1px solid #333;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-right: 16px;
  margin-bottom: 20px;
}

.no-select {
  -moz-user-select: none;
  /* Firefox私有属性 */
  -webkit-user-select: none;
  /* WebKit内核私有属性 */
  -ms-user-select: none;
  /* IE私有属性(IE10及以后) */
  -khtml-user-select: none;
  /* KHTML内核私有属性 */
  -o-user-select: none;
  /* Opera私有属性 */
  user-select: none;
  /* CSS3属性 */
}

/deep/.allPhoneQuery {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid #606369;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  .el-input__inner {
    height: 100% !important;
    padding: 0;
    border: 0;
    border-left: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
  }
}

/deep/ .el-tabs__content {
  .el-select {
    width: 100%;
  }

  .el-textarea__inner {
    min-height: 100px !important;
  }
}

/deep/.el-tabs__item {
  font-weight: bold !important;
}

/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278fe;
  border-color: #3278fe;

  &:hover {
    opacity: 0.7;
  }
}

.customColumns-dialog {
  /deep/ .el-transfer {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-transfer-panel {
      min-width: 300px !important;
    }
  }
}

#clientNums {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-card {
    flex: 1;
    box-shadow: none;
    overflow-y: scroll;
  }

  .num-upload {
    /deep/ .el-dialog__body {
      display: flex;
      justify-content: space-between;

      > div {
        // width: 100%;
      }

      .left {
        margin-right: 8px;
      }

      .right {
        flex: 1;
      }
    }
  }

  header {
    #clientNumber {
      margin-top: 20px;

      .num-wrap {
        background-color: pink;
        color: #fff;
        text-align: center;
        width: 19%;
        height: 60px;
        line-height: 24px;
        padding: 5px;
        box-sizing: border-box;

        .title {
          font-weight: bold;
          font-size: 16px;
          font-family: 宋体;
        }

        .number {
          font-size: 16px;
        }
      }
    }

    h1 {
      font-weight: normal;

      > span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .el-select {
          width: 100%;
        }

        .left-wrap {
          width: 20%;
          display: flex;
          align-items: center;

          span {
            margin: 6px;
            min-width: 60px;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 10px;
        // text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  // main {
  // padding-bottom: 60px;
  // box-sizing: border-box;

  .pagination {
    // position: fixed;
    width: 100%;
    // height: 40px;
    // border: 1px solid red;
    background-color: #fff;
    // bottom: 0;
    // right: 0;
    // z-index: 99;
    text-align: right;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    box-sizing: border-box;
    // margin: 20px 0;
  }

  // }

  .uploadBtn {
    width: 68%;
  }

  /deep/ .el-dialog {
    width: 70%;
  }

  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;

    .el-form-item {
      width: 50%;
      .el-form-item__content {
        .el-select {
          width: 100%;
        }
      }
    }
  }

  .client-details {
    /deep/ .el-drawer.rtl {
      height: 40%;
      bottom: 0;
      top: auto;

      .update-details {
        width: 70%;
      }

      .data-details {
        width: 300px;

        .title {
          color: #fff;
          background-color: #53a1f0;
          padding: 10px 20px;
          box-sizing: border-box;
          font-size: 18px;
          text-align: center;
        }

        .users {
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  .dblclick /deep/ .el-dialog__header {
    padding: 0;
  }

  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }

  /deep/ .el-input,
  .el-input-number {
    // width: 100% !important;
  }
}
</style>
