/**
 * reminder
 * 提醒
 */

import request from '@/utils/request'

// 删除
export const reminderDel = (data) => {
    return request({
        method: 'post',
        url: '/reminder/del',
        data
    })
}
// 编辑
export const reminderUpdate = (data) => {
    return request({
        method: 'post',
        url: '/reminder/update',
        data
    })
}
// 展示
export const reminderList = (data) => {
    return request({
        method: 'post',
        url: '/reminder/list',
        data
    })
}
// 添加
export const reminderAdd = (data) => {
    return request({
        method: 'post',
        url: '/reminder/add',
        data
    })
}