<template>
  <el-card id="clientsCharts">
    <header>
      <!-- <h1>新增客户详情</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap" v-if="roleName == '企业'">
            <span>部门</span>
            <el-select v-model="departId" placeholder="请选择部门" size="mini" filterable>
              <el-option v-for="item in underDepart" :key="item.id" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap" v-if="roleName == '组长' || roleName == '企业'">
            <span>员工</span>
            <el-select v-model="userId" placeholder="请选择员工" size="mini" filterable>
              <el-option v-for="item in underUser" :key="item.id" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <!-- <el-button @click="render('')" type="primary" size="mini"
            >今日</el-button>
          <el-button
            @click="render('/weekstatusAll')"
            type="primary"
            size="mini"
            >本周</el-button
          >
          <el-button
            @click="render('/monthstatusAll')"
            type="primary"
            size="mini"
            >当月</el-button
          > -->
          <el-date-picker v-model="monthTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" size="mini" :picker-options="pickerOptions">
          </el-date-picker>
          <el-button style="margin-left: 30px" type="success" icon="el-icon-search" @click="searchBtn" size="mini"
            class="searchBtn">查找</el-button>
          <el-button @click="reset()" type="success" size="mini" icon="el-icon-refresh">重置</el-button>
        </div>
      </div>
    </header>
    <hr>
    <main>
      <!-- <div class="content">
        <div class="client-type" v-for="v in infoData" :key="v.id">
          <span> {{ v.value }}</span>
          <p>{{ v.name }}</p>
        </div>
      </div> -->
      <div id="khChart" style="width: 60%; height: 400px"></div>

      <div id="chartPie" style="width: 40%; height: 400px"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneFindUser, phoneTimeAll, phoneCountAddStatus } from "@/api/phone";
export default {
  data() {
    return {
      timer: null,
      pickerOneDate: '',
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = '';
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          if (this.pickerOneDate) {
            const day = 30 * 24 * 3600 * 1000 // 15天
            let maxTime = this.pickerOneDate + day
            let minTime = this.pickerOneDate - day
            return time.getTime() > maxTime || time.getTime() < minTime
          } else {
            // return time.getTime() > Date.now()
          }
        }
      },
      monthTime: [],
      infoData: [],
      chartPie: null,
      departId: null,
      userId: null,
      underDepart: [], // 部门
      underUser: [], // 员工
      roleName: null,
    };
  },
  async created() {
    let rootDate = new Date().toLocaleDateString()
    this.monthTime = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
    // this.render("/daystatusAll");
    this.getList();
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id");
    // if (this.roleName == "管理员" || this.roleName == "企业") {
    if (this.roleName == "组长" || this.roleName == "企业") {
      this.getAllUser();
    }
    // this.getAllUser();
  },
  methods: {
    // 获取所有部门和员工
    getAllUser() {
      let res = phoneFindUser();
      return res.then((res) => {
        let data = res.data.data;
        // console.log("处理数据", data);
        // 处理数据
        // 部门
        this.underDepart = data.depart.map((v, i) => {
          // console.log("部门每项", v);
          return { id: i + 1, name: v.departName, value: v.id };
        });
        // 人员
        this.underUser = data.user.map((v, i) => {
          return { id: i + 1, name: v.realname, value: v.id };
        });
        // console.log("所有用户信息", res.data);
        return res.data;
      });
      // departId
      // userId
      // depart_name 部门名字
    },

    drawPieChart() {
      // 右边饼图
      this.chartPie = echarts.init(document.getElementById("chartPie"));
      this.chartPie.setOption({
        title: {
          x: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          // orient: "vertical",
          left: "top",
          data: this.infoData.map((v) => {
            return v.name
          }),
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "60%"],
            center: ["50%", "50%"],
            data: this.infoData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            label: {
              formatter: " {b|{b}：}{c}个  占{per|{d}%}  ",
              backgroundColor: "#F6F8FC",
              borderColor: "#8C8D8E",
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: "#6E7079",
                  lineHeight: 22,
                  align: "center",
                },
                hr: {
                  borderColor: "#8C8D8E",
                  width: "100%",
                  borderWidth: 1,
                  height: 0,
                },
                b: {
                  color: "#4C5058",
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 33,
                },
                per: {
                  color: "#fff",
                  backgroundColor: "#4C5058",
                  padding: [3, 4],
                  borderRadius: 4,
                },
              },
            },
          },
        ],
      });

      // 左边柱状图
      this.drawleftChart();
    },
    drawleftChart() {
      this.chartPie = echarts.init(document.getElementById("khChart"));
      this.chartPie.setOption({
        title: {
          text: "新增客户意向统计",
        },
        color: ["#3278FE"],

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          // orient: "vertical",
          left: "top",
          data: [
            "成交客户",
            "意向客户",
            "一般客户",
            "无意向客户",
            "黑名单",
            "无状态",
            "未接通",
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.infoData.map((v) => {
            return v.name
          }),
        },
        series: [
          {
            name: "2011",
            type: "bar",
            data: this.infoData,
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "#3278FE",
              },
              formatter: function (params) {
                return params.value + "名";
              },
            },
          },
        ],
      });
    },
    drawCharts() {
      this.drawPieChart();
    },
    // 展示
    getList() {

      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        if (this.monthTime) {
          this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[0]);
          this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.monthTime[1]);
        }
        let params = {
          departId: this.departId,
          userId: this.userId,
          sta: this.sta,
          end: this.end,
        };
        // console.log("params", params);
        // console.log(" this.monthTime", this.monthTime);

        let res = phoneCountAddStatus(params);
        res.then((res) => {
          let data = [
            "一般客户",
            "意向客户",
            "无意向客户",
            "成交客户",
            "黑名单",
            "无状态",
            "未接通",
          ];
          // console.log("res.data",res.data.data);
          if (res.data.data['1']) {
            this.$nextTick(() => {
              this.$emit('sendYXKh', res.data.data['1']);
            })
          }
          let arr = [];
          for (let i = 0; i <= 6; i++) {
            arr.push({ id: i, name: data[i], value: res.data.data[i] || 0 });
          }

          this.infoData = arr.sort(function (a, b) {
            return b.value - a.value;
          });
          let echars = this.drawCharts();
        });

        this.timer = null
      }, 500);
    },
    // 搜索
    searchBtn() {
      this.getList();
    },
    // 重置
    reset() {
      this.userId = null;
      this.departId = null;
      // this.render("/daystatusAll");
      this.sta = null;
      this.end = null;
      let rootDate = new Date().toLocaleDateString()
      this.monthTime = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
  },
  updated: function () {
    this.drawCharts();
  },
};
</script>

<style lang="less" scoped>
#clientsCharts {
  header {
    .search {
      display: flex;
      // margin-top: 20px;
      // flex-direction: column;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    display: flex;
    justify-content: space-between;

    .content {
      width: 50%;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      padding: 30px;
      box-sizing: border-box;
      text-align: center;

      .client-type {
        text-align: center;
        width: 158px;
        padding: 20px 0;
        box-sizing: border-box;
      }

      .client-type>span {
        font-size: 32px;
      }

      .client-type>p {
        font-size: 18px;
      }
    }
  }
}
</style>