/**
 * shangjiContract
 * 合同--商机云
 */

import request from '@/utils/request'

// 新增合同
export const shangjiContractShangjiAdd = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiAdd',
        data
    })
}

// 公司名称模糊查询
export const GSFindName = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/find-name',
        data
    })
}

// 删除合同
export const shangjiContractShangjiDelete = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiDelete',
        data
    })
}
// 编辑合同
export const shangjiContractShangjiUpdate = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiUpdate',
        data
    })
}
// 展示合同
export const shangjiContractShangjiSelect = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiSelect',
        data
    })
}
// 待审批合同
export const shangjiContractShangjiRemindContract = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiRemindContract',
        data
    })
}
// 已审批合同
export const shangjiContractShangjiParticipantContract = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiParticipantContract',
        data
    })
}
// 审批--查询
export const shangjiContractShangjiSelectContract = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiSelectContract',
        data
    })
}
// 审批--修改
export const shangjiContractShangjiUpdateContract = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/shangjiUpdateContract',
        data
    })
}
// 查询产品信息
export const shangjiContractSelectshangjiContractPrice = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/selectshangjiContractPrice',
        data
    })
}
// 
export const shangjiContractAppBind = (data) => {
    return request({
        method: 'post',
        url: '/shangjiContract/app_bind',
        data
    })
}
